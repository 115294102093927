.LogItemsContainer {
  display: grid;
  grid-template-columns: auto auto auto;
}

.GridButton {
  background-color: unset;
  border: unset;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
  padding: 0;  
  cursor: pointer;
  font: unset;
}
.GridButton:focus {
  outline: 0;
}

.GridItem, .GridButton {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid darkgray;
  padding: 0.5rem 0;
  white-space: pre;
}

.ActiveGridButton {
  text-decoration: underline;
}

.CenterGrid {
  padding-left: 0.25rem;
  padding-right: 0.25rem;  
}

.ScoreGridItem {
  padding: 0.5rem 0;
  font-weight: bold;
}

.FirstScore {
  border-top: 3px solid darkgray;
}

.plusPoints, .minusPoints, .zeroPoints {
  display: unset;  
  text-align: end;
}

.plusPoints {
  color: green;
  font-weight: bold;
}

.minusPoints {
  color: red;
  font-weight: bold;
}

.LogContainer {  
  background-color: white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  margin: 2rem auto 2rem auto;
  padding: 1rem;
  border-radius: 1rem;
  max-width: 600px;
}
@media screen and (max-width: 600px) {
  .LogContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.Title {
  font-size: x-large;
  color: darkgray;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-bottom: 1rem;
  text-transform: uppercase;
}


.IconsContainer {
  display: flex;
  justify-content: space-between;
  width: 4rem;
}

.SingleIcon {
  justify-content: flex-end;
}
