.ColorSelector {
  display: flex;
  position: relative;
}

.ColorSelected, .ColorOption {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
}

.ColorSelectorOptions {
  position: absolute;
  background-color: white;
  border-radius: 0.5rem;
  z-index: 1;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  transform: translateY(1.5rem);
  width: 13.5rem;
  transform: translate(-11rem, 2rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}