.Header {
  margin: auto;
  max-width: 600px;
  color: white;
  padding: 0.5rem 0.5rem 1rem 0.5rem;  
}

.TopHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: x-large;
  margin-bottom: 0.5rem;
}

.IconsContainer {
  display: flex;
  justify-content: space-between;
  width: 8.5rem;
}

@media screen and (max-width: 600px) {
  .IconsContainer {    
    display: none;
  }
}

.Image {
  margin-right: 0.5rem;
  transform: translateY(0.25rem);
}
