.GuideContainer {  
  background-color: white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  margin: 4rem auto 1rem auto;
  padding: 1rem;
  border-radius: 1rem;
  max-width: 600px;
}
@media screen and (max-width: 600px) {
  .GuideContainer {
    border-radius: unset;
  }
}

.linkButton, .iconLinkButton {
  background-color: unset;
  border: unset;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}

.iconLinkButton {
  margin-left: 1rem;
}

.H1, .H2 {
  color: #454545;
}