.Container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.GameModeContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.BottomButtomContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.SettingsContainer {  
  background-color: white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  margin: 0 auto 2rem auto;
  padding: 1rem;
  border-radius: 1rem;
  max-width: 600px;
}
@media screen and (max-width: 600px) {
  .SettingsContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.Title {
  font-size: x-large;
  color: darkgray;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-bottom: 1rem;
  text-transform: uppercase;
}

.Setting, .GameTarget {
  border-top: 1px solid darkgray;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GameTarget {
  border-top: unset;
}

.TeamNameInput {
  border-radius: 0.5rem;
  background-color: lightgray;
  font-size: large;
  padding: 0.5rem 1rem;
  border: none;
  color: gray;
  width: 3rem;
}

.Team {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.TeamNameInput {
  width: 9rem;
}

.TeamInfos {
  display: flex;
}

.TeamCountButtons {
  width: 4rem;
  display: flex;
  justify-content: space-between;
  color: gray;
}
