.Container {
  width: 100%;
  height: 100%;
  overflow: auto;
  transition: background-color 500ms linear;
}

.LogContainer {  
  background-color: white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  margin: 3rem auto 2rem auto;
  padding: 1rem;
  border-radius: 1rem;
  max-width: 600px;
}
@media screen and (max-width: 600px) {
  .LogContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.Title {
  font-size: x-large;
  color: darkgray;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-bottom: 1rem;
  text-transform: uppercase;
}