.Container {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.PauseContainer {  
  background-color: white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0 auto 2rem auto;
  padding: 1rem;
  border-radius: 1rem;
  max-width: 600px;
}
@media screen and (max-width: 600px) {
  .PauseContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.Title {
  font-size: x-large;
  color: darkgray;
  padding-bottom: 1rem;  
}

.ButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}