.ScoresContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-bottom: 3rem;
}

.ScoreName, .ScoreTotal, .RoundAddition {  
  color: white;
  margin-top: 1.5rem;
  font-size: x-large;
}

.TimeGameSmallFont {
  font-size: large;
}

.RoundAddition {
  color: lightgreen !important;  
  text-align: end;
}

.Black {
  color: black;
}

.CurrentTeamScore {  
  font-weight: bold;
}

.WinnerTeam {
  color: #ffbc40;
  font-weight: bold;
}

.ScoreTotal {  
  text-align: end;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.ScoreName {  
  flex: 1;  
}

@media screen and (min-width: 600px) {
  .ScoresContainer {
    align-items: center;
  }
  .ScoreContainer {
    width: 600px;
  }  
}