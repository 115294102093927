.WhiteContainer {
  background-color: white;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .WhiteContainer {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.Container, .WhiteContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 630px;  
  border-radius: 0.5rem;
}

.Container {
  transition: background-color 500ms linear;
  padding: 0 0.5rem 1rem 0.5rem;
}

.word {
  display: flex;
  justify-content: center;
  text-transform: uppercase;  
  font-size: xx-large;
  margin-bottom: 1rem;
  color: white;
}

.WordButtonContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.WordsContainer {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
