
.Button {
  flex: 1;
  max-width: 200px;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem;
  flex-direction: column;
  align-items: center;  
  font-size: 130%;
  outline: none;
  border: none; 
  background-color: white;
  align-self: stretch;
  font-weight: bold;
  flex-basis: auto;
}

.SettingsMode {
  max-width: unset;
  color: darkgrey !important;
  background-color: lightgray;
  opacity: 0.5;
}

.SettingsButton {  
  color: darkgrey !important;
  background-color: lightgray; 
}

.Button:disabled {
  opacity: unset;
}

.Button:active {
  opacity: 0.5;
}
.SettingsMode:active {
  opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
  .Button:hover:not(:disabled) {
    opacity: 0.5;
  }
  .SettingsMode:hover:not(:disabled) {
    opacity: 1;
  }
}