.Container {
  border-top: 1px solid darkgray;
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ChooseBox {
  border-radius: 0.5rem;
  background-color: lightgray;
  font-size: large;
  border: none;
  color: gray;
  display: flex;
  width: 11rem;
  padding: 0.5rem 0;
  position: relative;
}

.ChooseBoxSelected {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 1rem;
}

.ChooseBoxOptions {
  position: absolute;
  background-color: white;
  width: inherit;
  border-radius: 0.5rem;
  z-index: 1;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  margin-top: 0.5rem;
  display: block;
  padding: 0.5rem 0;
  transform: translateY(1.5rem);
}

.ChooseBoxOption {
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ChooseBoxOption:hover {
  background-color: lightgray;
}
