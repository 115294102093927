.footer {
  width: 100%;
  color: white;
  padding-bottom: 20px;
  padding-top: 20px;
  text-align: center;
  font-family: verdana;
  display: flex;
  flex-direction: column;
}
.footerItem, .footerButton {
  color: white;
  text-align: center;
  font-family: verdana;
  margin: 0;
}
.footerButton {
  background-color: unset;
  border: unset;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  font: unset;
}
.footerButton:focus {
  outline: 0;
}
.footerMiddle {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.footerContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-self: stretch;
  align-items: stretch;
}

@media screen and (max-width: 600px) {
  .footerButton {
    margin-bottom: 3.25rem;
  }
}