.Container {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgb(133, 0, 204);
  color: white;
  z-index: 2000;
  display: flex;
  flex: 1;
  justify-content: space-around;
  width: 100%;
  height: 3.25rem;
  font-size: x-large;
  visibility: hidden;  
}

@media screen and (max-width: 600px) {
  .Container {
    visibility: unset;
  }
}

.Title {
  font-size: xx-small;
  margin: 0;
}

.HideTitle {
  visibility: hidden;
}