.MainHeader {
  color: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.BottomHeader, .AllGuessHeader {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}

.AllGuessHeader {  
  font-size: x-large;
  text-transform: uppercase;
  justify-content: center;
}

.HeaderItem {
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin: 1rem 2rem 0 2rem;
}
@media screen and (max-width: 600px) {
  .HeaderItem {
    margin: 1rem 0.75rem 0 0.75rem;
  }
}

.CurrentPlayer {
  margin-left: 1rem;
  font-size: x-large;
  text-align: center;
}

.Round, .TimeLeft, .RoundScore, .Freepass {
  font-size: x-large;
  text-align: center;
}
